<template>
  <div class="amazon">
    <van-nav-bar title="amazon" left-arrow />
    <div class="main">
      <!-- 任务列表   -->
      <div class="item">
        <!-- 任务编号 -->
        <p>Número de pedido: {{ task_list.order_number }}</p>
        <!-- 任务详情 -->
        <div class="details">
          <!-- 图片  -->
          <img :src="task_list.examine_demo" alt="" />
          <!-- 金额 -->
          <div class="money">
            <div>
              <p>Nome do produto：</p>
              <span>{{ task_list.title }}</span>
            </div>
            <div>
              <p>Comissão disponível：</p>
              <span>{{ task_list.reward_price }} {{ currency_name }}</span>
            </div>
          </div>
        </div>
        <!-- 按钮 -->
        <div class="button">
          <div @click="pay()">Pagamento</div>
        </div>
      </div>
    </div>
    <!-- 当前页面所有的 弹窗 -->
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <!-- 完成任务的弹窗 -->
        <div class="complete" v-if="show">
          <div class="title">
            <div class="jianbian"></div>
            <span
              >完成支付 {{ task_list.dislog.which_one_pac }}/{{
                task_list.dislog.success_count
              }}</span
            >
            <div class="jianbian1"></div>
          </div>
          <div class="center">
            <img
              src="../assets/img/success.png"
              alt=""
              width="91"
              height="84"
            />
            <div class="text">
              <span>获得佣金: {{ task_list.dislog.reward_price }} </span>
              <span>账户余额: {{ task_list.dislog.total_amount }}</span>
            </div>
            <p>Task progress</p>
            <!-- 进度条 -->
            <div class="progress-bar blue stripes">
              <div style="width: 35%;"></div>
            </div>
          </div>
          <!-- 继续完成按钮 -->
          <div class="completeButton">继续完成</div>
        </div>
        <!-- 未完成全部任务 -->
        <div class="complete shibai" v-if="false">
          <div class="title">
            <span>未完成全部任务</span>
          </div>
          <div class="center">
            <img src="../assets/img/shibai.png" alt="" width="91" height="84" />
            <div class="text">
              <span>你有未完成的任务订单， 完成后才可以提现。</span>
            </div>
            <p class="shibai">Task progress</p>
            <!-- 进度条 -->
            <div class="progress-bar blue stripes">
              <div style="width: 35%;"></div>
            </div>
          </div>
          <!-- 继续完成按钮 -->
          <div class="completeButton">前往充值</div>
        </div>
        <!-- 未完成全部任务  邀请好友领取现金-->
        <div class="complete shibai" v-if="false">
          <div class="title">
            <span>未完成全部任务</span>
          </div>
          <div class="center">
            <img src="../assets/img/shibai.png" alt="" width="91" height="84" />
            <div class="text">
              <span>你有未完成的任务订单， 完成后才可以提现。</span>
            </div>
            <p class="shibai">Task progress</p>
            <!-- 进度条 -->
            <div class="progress-bar blue stripes">
              <div style="width: 35%;"></div>
            </div>
          </div>
          <!-- 继续完成按钮 -->
          <div class="completeButton">去完成任务</div>
          <div
            style="width: 178px;height: 33px;border-radius: 8px;
            border: solid 1px #5e59e9;text-align:center;line-height:30px;
            font-size:18px;margin-top:10px;color: #5e59e9;"
          >
            邀请好友得现金
          </div>
        </div>
        <!-- 已完成全部任务 -->
        <div class="complete" v-if="false">
          <div class="title">
            <div class="jianbian ywc"></div>
            <span>已完成全部任务</span>
            <div class="jianbian1 ywc"></div>
          </div>
          <div class="center">
            <img
              src="../assets/img/gaoxing.png"
              alt=""
              width="73"
              height="86"
            />
            <div class="text">
              <span style="font-size: 12px;"
                >顺利完成任务，佣金已发到账户请提现，感谢你的参与</span
              >
            </div>
            <p class="shibai">Task progress</p>
            <!-- 进度条 -->
            <div class="progress-bar blue stripes">
              <div style="width: 35%;"></div>
            </div>
          </div>
          <!-- 继续完成按钮 -->
          <div class="button">
            <div class="completeButton">前往充值</div>
            <div class="completeButton">去提现</div>
          </div>
        </div>
        <!-- 已完成全部任务 邀请好友得现金 -->
        <div class="complete" v-if="false" style="height:402px">
          <div class="title">
            <div class="jianbian ywc"></div>
            <span>已完成全部任务</span>
            <div class="jianbian1 ywc"></div>
          </div>
          <div class="center">
            <img
              src="../assets/img/gaoxing.png"
              alt=""
              width="73"
              height="86"
            />
            <div class="text">
              <span style="font-size: 12px;"
                >顺利完成任务，佣金已发到账户请提现，感谢你的参与</span
              >
            </div>
            <p class="shibai">Task progress</p>
            <!-- 进度条 -->
            <div class="progress-bar blue stripes">
              <div style="width: 35%;"></div>
            </div>
          </div>
          <!-- 继续完成按钮 -->
          <div class="button">
            <div class="completeButton">前往充值</div>
            <div class="completeButton">去提现</div>
          </div>
          <div
            class="yaoqing"
            style="width: 239px;height: 33px;border-radius: 8px;
            border: solid 1px #5e59e9;text-align:center;line-height:33px;
            color: #5e59e9;font-size: 18px;font-weight: 400;margin-top:30px;"
          >
            邀请好友得现金
          </div>
        </div>
        <!-- 支付失败 -->
        <div class="complete shibai" v-if="false">
          <div class="title">
            <div class="jianbian shibai"></div>
            <span>支付失败</span>
            <div class="jianbian1 shibai"></div>
          </div>
          <div class="center shibai">
            <img src="../assets/img/shibai.png" alt="" width="73" height="86" />
            <div class="text">
              <span style="font-size: 12px;"
                >你在完成n个任务，m订单时余2额不足，请完成充值，稍后连通佣金一起归还（本金➕佣金）</span
              >
            </div>
            <p class="shibai">Task progress</p>
            <!-- 进度条 -->
            <div class="progress-bar blue stripes">
              <div style="width: 35%;"></div>
            </div>
          </div>
          <!-- 按钮 -->
          <div class="completeButton">前往充值</div>
        </div>
        <!-- 礼盒  Gift box-->
        <div class=" giftBox" v-if="false">
          <div class="top_title">恭喜你</div>
          <div class="receiveTask">领取任务成功</div>
          <div class="img">
            <img
              src="../assets/img/giftBox.png"
              alt=""
              width="161"
              height="93"
            />
          </div>
          <div class="desc">
            请前往任务中心，完成任务
          </div>
          <div class="button">
            <div>去完成</div>
          </div>
        </div>
        <!-- 礼盒 2 -->
        <div class=" giftBox" style="height:380px;width:259px" v-if="false">
          <div class="top_title">恭喜你</div>
          <div class="receiveTask">领取任务成功</div>
          <div style="margin-top:10px;">
            请先签名，然后前往任务中心，完成任务
          </div>
          <div class="img" style="margin-top:0">
            <img
              src="../assets/img/giftBox2.png"
              alt=""
              width="159"
              height="159"
            />
          </div>
          <input
            class="desc"
            style=" width: 169px;height: 36px; border-radius: 8px;border: solid 1px #5e59e9;padding-left:10px;
            margin-top:4%;"
            placeholder="签名"
          />
          <div class="button">
            <div>去完成</div>
          </div>
          <div style="display:flex;align-items:center;margin-top:10px;">
            <input type="checkbox" />
            <div>
              我已阅读并同意<span style="color: rgb(94, 89, 233);"
                >《幸运任务领取条款》</span
              >
            </div>
          </div>
        </div>
        <!-- 高佣金任务 -->
        <div class="commissionTasks" v-if="false">
          <div class="main">
            <div class="top_title">高佣金任务</div>
            <div class="center">
              <div class="rwjs">任务介绍</div>
              <div class="one">1.系统推荐的高额佣金任务</div>
              <div class="two">2.完成所有任务可获得10k~50k</div>
              <div style="text-align:right;padding-right:10px;">
                >>获得更多详情
              </div>
            </div>
          </div>
          <!-- 按钮 -->
          <div class="button">
            <div>接受</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "amazon",
  data() {
    return {
      show: false, //是否显示遮罩层
      currency_name: JSON.parse(localStorage.getItem("InitData")).setting
        .currency_name, //货币符号
      task_list: "", //任务列表 item
    };
  },
  created() {
    this.加载中 = this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    this.获取下一个任务();
  },
  methods: {
    pay() {
      this.show = true;
      this.$Dialog.Loading();
      let json = {
        task_id: this.task_list.user_task_id,
      };
      // this.task_list.dis_id !=0 现在的任务  =0 以前的任务
      if (this.task_list.dis_id == 0) {
        this.$Model.finishShopTask(json, (data) => {
          this.show = true;
          // console.log('finishShopTask', data);
          this.$Dialog.Close();
          if (data.code == 1) {
            this.$Dialog.Toast("Pagamento com sucesso");
            this.获取下一个任务();
          } else if (data.code == -4) {
            this.获取下一个任务();
          } else if (data.code == -2) {
            this.$dialog({
              message:
                "O seu saldo é insuficiente para realizar a tarefa, ainda preciso de " +
                data.code_dec +
                "R$, recarregue primeiro.",
              confirmButtonText: "Recarregar",
            }).then(() => {
              this.$router.push("/user/mixRecharge");
            });
          }
        });
        return false;
      }
      //完成现在的任务
      this.$Model.finishDispatchTask(json, (data) => {
        this.$Dialog.Close();
        if (data.code == 1) {
          // console.log("完成现在的任务成功");
          this.获取下一个任务();
        } else if (data.code == -4) {
          this.获取下一个任务();
        } else if (data.code == -2) {
          this.$dialog({
            message: `O seu saldo é insuficiente para realizar a tarefa, ainda preciso de
              ${data.code_dec}
              R$, recarregue primeiro.`,
            confirmButtonText: "Recarregar",
          }).then(() => {
            this.$router.push("/user/mixRecharge");
          });
        }
      });
    },
    获取下一个任务() {
      this.$Model.shopDispatchDetail((data) => {
        if (Object.keys(data).length > 0) {
          if (data.code == 11) {
            //当前包完成，但是当前任务流程未全部完成 跳转抢任务页面
            this.$dialog({
              message: this.$t("grabtask[27]"),
              confirmButtonText: this.$t("grabtask[9]"),
            }).then(() => {
              this.$router.push("/user/Grabtask");
            });
          } else if (data.code == 12) {
            //当前包完成，当前任务流程全部完成
            this.$router.push({
              path: "/myTask",
              query: {
                code: data.code,
              },
            });
          }
          this.task_list = data;
          this.isShow = true;
          // this.加载中.clear();
        } else {
          this.$dialog({
            message: this.$t("grabtask[23]"),
            confirmButtonText: this.$t("grabtask[9]"),
          }).then(() => {
            this.$router.push({
              path: "/myTask",
              query: {
                code: 12,
              },
            });
          });
        }
        this.加载中.close();
      });
    },
  },
  components: {},
};
</script>

<style scoped>
.amazon {
  height: 100vh;
  background-color: rgb(245, 245, 245);
}
.amazon > .main {
  padding: 0 10px 0 22px;
}
.amazon > .main > .item {
  position: relative;
  width: 343px;
  height: 160px;
  margin-top: 15px;
  padding: 10px 5px 0 5px;
  border-radius: 15px;
  background: #ffffff;
}
.amazon > .main > .item > p {
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 5px;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  border-bottom: solid 0.5px rgba(151, 151, 151, 0.3);
}
.amazon > .main > .item > .details {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.amazon > .main > .item > .details > img {
  width: 90px;
  height: 72px;
  border: solid 1px #dddddd;
}
.amazon > .main > .item > .details > .money {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
}
.amazon > .main > .item > .details > .money p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
.amazon > .main > .item > .details > .money span {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.amazon > .main > .item > .button > div {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 76px;
  height: 26px;
  font-size: 11px;
  line-height: 26px;
  text-align: center;
  border-radius: 5px;
  border: solid 1px #0019ff;
}
.amazon .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.amazon .wrapper > .complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 279px;
  height: 341px;
  border-radius: 20px;
  background: #fff url("../assets/img/amazon_bg.png") no-repeat;
  background-size: contain;
}
.amazon .wrapper > .shibai {
  background-image: url("../assets/img/shibai_bg.png");
}
.amazon .wrapper > .shibai {
  width: 279px;
  height: 363px;
}
.amazon .wrapper > .giftBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 259px;
  height: 312px;
  border-radius: 20px;
  border: solid 1px rgba(169, 169, 169, 0.4);
  background: #ffffff;
}
.amazon .wrapper > .giftBox > .top_title {
  position: absolute;
  top: -6%;
  width: 90%;
  min-height: 38px;
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  background-image: url("../assets/img/gongxi.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.amazon .wrapper > .giftBox > .receiveTask {
  margin-top: 10%;
  color: #0e0e0e;
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
}
.amazon .wrapper > .giftBox > .img {
  margin-top: 15%;
}
.amazon .wrapper > .giftBox > .desc {
  margin-top: 8%;
  color: #0e0e0e;

  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}
.amazon .wrapper > .giftBox > .button > div {
  text-align: center;
  width: 170px;
  height: 33px;
  margin-top: 10%;
  color: #fff;
  line-height: 33px;

  font-size: 18px;
  font-weight: 400;
  border-radius: 8px;
  background: #5e59e9;
}
.amazon .wrapper > .complete > .title {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  align-items: center;
  width: max-content;
  height: 33px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
}

.amazon .wrapper > .complete > .title > .jianbian {
  width: 50px;
  height: 3px;
  border-radius: 1.5px;
  background: linear-gradient(to left, #8385ff 0%, rgb(160, 158, 249) 40%);
  margin-right: 10px;
}
.amazon .wrapper > .complete > .title > .jianbian1 {
  width: 50px;
  height: 3px;
  margin-left: 10px;
  border-radius: 1.5px;
  background: linear-gradient(270deg, rgb(163, 160, 249), #8385ff);
}
.amazon .wrapper > .complete > .title > .ywc {
  width: 30px;
}
.amazon .wrapper > .complete > .title > .shibai {
  width: 60px;
}
.amazon .wrapper > .complete > .center {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  width: 239px;
  height: 146px;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.17);
}
.amazon .wrapper > .complete > .shibai {
  height: 160px;
}
.amazon .wrapper > .complete > .center > p {
  margin-top: 10%;
  color: #5e59e9;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
.amazon .wrapper > .complete > .center > .shibai {
  margin-top: 1%;
}
.amazon .wrapper > .complete > .center > img {
  position: absolute;
  top: -35%;
}
.amazon .wrapper > .complete > .center > .text {
  margin-top: 15%;
}
@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}
.amazon .wrapper > .complete > .center > .progress-bar {
  background-color: rgb(116, 147, 244);
  height: 25px;
  padding: 5px;
  width: 90%;
  margin: 10px 0 0 0;
  border-radius: 5px;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.31);
}
.amazon .wrapper > .complete > .center > .stripes div {
  background-size: 15px 15px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0.25, rgba(255, 255, 255, 0.15)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, transparent),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -moz-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -ms-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    135deg,
    rgba(54, 193, 226) 25%,
    transparent 25%,
    transparent 50%,
    rgba(54, 193, 226) 50%,
    rgba(54, 193, 226) 75%,
    transparent 75%,
    transparent
  );
}
.amazon .wrapper > .complete > .center > .blue div {
  background-color: rgb(131, 230, 255);
}
.amazon .wrapper > .complete > .center > .progress-bar div {
  display: inline-block;
  height: 100%;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgb(255 255 255 / 50%) inset;
  transition: width 0.4s ease-in-out;
}

.amazon .wrapper > .complete > .completeButton {
  margin-top: 30px;
  width: 125px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 8px;
  background: #5e59e9;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
}
.amazon .wrapper > .complete > .button {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.amazon .wrapper > .complete > .button > div {
  height: 33px;
  color: #5e59e9;
  font-size: 18px;
  font-weight: 400;
  line-height: 33px;
  padding: 0 20px;
  border-radius: 5px;
  border: solid 1px #5e59e9;
}
.amazon .wrapper > .complete > .button > div:last-child {
  color: #fff;
  background-color: #5e59e9;
}
.amazon .wrapper > .commissionTasks {
  z-index: 9999;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 357px;
  height: 357.53px;
  background: transparent url("../assets/img/commissionTasks.png") no-repeat;
  background-size: contain;
  background-position: 8px -50px;
}
.amazon .wrapper > .commissionTasks > .main {
  position: relative;
  width: 278px;
  height: 230px;
  border-radius: 20px;
  border: solid 5px #5e59e9;
  background-color: #fff;
  text-align: center;
}
.amazon .wrapper > .commissionTasks > .main > .top_title {
  position: absolute;
  top: -10%;
  left: 5%;
  width: 173.72px;
  height: 45px;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 43px;

  background: linear-gradient(270deg, #676bff, #7c89ff);
}
.amazon .wrapper > .commissionTasks > .main > .center {
  line-height: 30px;
  margin-top: 15%;
}
.amazon .wrapper > .commissionTasks > .main > .center > .rwjs {
  color: rgb(92, 88, 233);
  font-size: 24px;
  font-weight: 400;
}
.amazon .wrapper > .commissionTasks > .main > .center > .one,
.amazon .wrapper > .commissionTasks > .main > .center > .two {
  padding-left: 20px;
  text-align: left;

  font-size: 18px;
  font-weight: 400;
}
.amazon .wrapper > .commissionTasks > .main > .center > .one {
  margin-top: 5%;
}
.amazon .wrapper > .commissionTasks > .main > .center > .two {
  margin-top: 2%;
}
.amazon .wrapper > .commissionTasks > .button {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 125px;
  height: 33px;
  border-radius: 8px;
  background: #5e59e9;
  color: #ffffff;

  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.24);
}
::v-deep .van-nav-bar {
  background-color: transparent;
}
.van-nav-bar >>> .van-icon {
  color: #fff;
  font-size: 24px;
}
.van-nav-bar >>> .van-nav-bar__content {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: rgb(79, 81, 192);
}
::v-deep .van-nav-bar__title {
  font-size: 24px;
  color: #fff;
}
</style>
